// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAvGQPuYo-IJCojnohli3RkkrqmyiDCJ3c",
  authDomain: "realestateseoservice-f784e.firebaseapp.com",
  projectId: "realestateseoservice-f784e",
  storageBucket: "realestateseoservice-f784e.appspot.com",
  messagingSenderId: "69329861258",
  appId: "1:69329861258:web:9e190604168f12f43a0b73"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;